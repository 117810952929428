<template>
    <div data-scroll :data-scroll-id="name" class="divider-container">
        <img data-scroll data-scroll-speed="-2" :src="item.image" class="image" alt="">
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import first from 'lodash/first'

export default {
    props: {
        data: {
            type: Object,
            default: () => {
            }
        },
        name: {
            type: String,
            default: ''
        },
    },
    computed: {
        ...mapGetters({
            device: 'resize/geDevice'
        }),
        item() {
            return first(this.data?.list?.map(item => ({
                image: item.image?.[0]?.devices?.[this.device?.type]
            })))
        }
    },

}
</script>

<style lang="scss">
.divider-container {
    width: 100%;
    height: 178px;
    overflow: hidden;
    margin-top: 144px;

    @media only screen and (max-width: 1365px) and (min-width: 768px) {
        margin-top: 89px;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
       height: 134px;
    }

    @media only screen and (max-width: 767px) {
        height: 72px;
        margin-top: 55px;
    }

    .image {
        height: calc(100% + 150px);
        width: 100%;
        object-fit: cover;
        top: -75px;
        position: relative;
    }
}
</style>
